// eslint-disable-next-line prefer-destructuring, @typescript-eslint/dot-notation
const env = window['env'];

const appConfig = {
    tenantApplicationClientId: env.TENANT_APPLICATION_CLIENT_ID,
    tenantName: env.TENANT_NAME,
    userFlowName: 'B2C_1A_TEN_NKP_UNIVERSAL_SIGN_IN_V1',
    services: {
        accountApi: '/account-api',
        distributedGenerationApi: '/distributed-generation-api',
        distributedGenerationUserUploadApi: `${window.location.protocol}//${window.location.host}/distributed-generation-user-upload-api`,
        gisMiddlewareApi: '/gis-api',
        gisMiddlewareApiKey: env.DIG_APP_API_KEY_MIDDLEWARE_GIS,
        installerApi: '/installer-api',
    },
};

export default appConfig;
