import appConfig from '@/app.config';
import { AuthenticatedContent, InitializingMsalProvider, msalConfig } from '@ten-netzkundenportal/ui-auth-utils';
import { LoadingSpinner } from '@ten-netzkundenportal/ui-components';
import '@ten-netzkundenportal/ui-components/dist/base.css';
import '@ten-netzkundenportal/ui-document-upload/dist/base.css';
import * as React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import './base.css';
import { CONNECTION_ASSURANCE_CONFIRMATION_ACCEPT, MAINTENANCE, REQUEST_ROOT, START } from './routes';

const Start = React.lazy(() => import(/* webpackPrefetch: true */ './start/Start'));
const Maintenance = React.lazy(() => import(/* webpackPrefetch: true */ './maintenance/Maintenance'));
const RequestRoot = React.lazy(() => import(/* webpackPrefetch: true */ './open-request/RequestRoot'));
const AcceptConnectionAssurance = React.lazy(
    () => import(/* webpackPrefetch: true */ './connection-assurance/AcceptConnectionAssurance'),
);
const AppRouting = React.lazy(() => import(/* webpackPrefetch: true */ './app-routing.component'));

// The error is structured the following way containing the following ID used to react on the error
// ServerError: access_denied: AADB2C90118: The user has forgotten their password.
export const errorCodePasswordForgotButton = 'AADB2C90118';

export default (): React.ReactElement => (
    <React.StrictMode>
        <InitializingMsalProvider msalConfig={msalConfig(appConfig)}>
            <div className="grid place-items-center gap-y-5 h-fit">
                <React.Suspense
                    fallback={
                        <div className="w-full h-[50rem] flex flex-col items-center">
                            <div className=" flex flex-row min-h-full flex-grow items-center">
                                <div className="w-20 h-20">
                                    <LoadingSpinner />
                                </div>
                            </div>
                        </div>
                    }
                >
                    <Router>
                        <Switch>
                            <Route exact path={START}>
                                <Start />
                            </Route>
                            <Route exact path={MAINTENANCE}>
                                <Maintenance />
                            </Route>
                            <Route path={REQUEST_ROOT}>
                                <RequestRoot />
                            </Route>
                            <Route path={CONNECTION_ASSURANCE_CONFIRMATION_ACCEPT}>
                                <AuthenticatedContent>
                                    <AcceptConnectionAssurance />
                                </AuthenticatedContent>
                            </Route>
                            <Route>
                                <AppRouting />
                            </Route>
                        </Switch>
                    </Router>
                </React.Suspense>
            </div>
        </InitializingMsalProvider>
    </React.StrictMode>
);
