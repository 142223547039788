// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#single-spa-application\\:\\@ten-nkp\\/distributed-generation-app .form-layout {
    grid-template-columns: minmax(min-content, 35rem) minmax(min-content, 35rem);
}

@media (max-width: 1024px) {
    #single-spa-application\\:\\@ten-nkp\\/distributed-generation-app .form-layout {
        grid-template-columns: none;
    }
}

#single-spa-application\\:\\@ten-nkp\\/distributed-generation-app .full-main-container-width {
    margin-left: -3rem;
    margin-right: -3rem;
}

#single-spa-application\\:\\@ten-nkp\\/distributed-generation-app .electricity-connection {
    border-color: #33ab3e;
    color: #33ab3e;
}

#single-spa-application\\:\\@ten-nkp\\/distributed-generation-app .speech-bubble:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-top: 6px solid transparent;
    border-bottom: 13px solid #fff;
    top: 30px;
    left: -20px;
    transform: rotate(65deg);
}

#single-spa-application\\:\\@ten-nkp\\/distributed-generation-app .plant-components-details-table {
    display: grid;
    grid-template-columns: 24fr 18fr 9fr 7fr 8fr 8fr 8fr 8fr 6fr;
    column-gap: 0.75rem;
}

#single-spa-application\\:\\@ten-nkp\\/distributed-generation-app .checklist-info-i {
    display: inline-block;
    vertical-align: middle;
    fill: currentColor;
    width: 1rem;
    margin-bottom: 0.1rem;
    margin-left: 0.5rem;
}

#single-spa-application\\:\\@ten-nkp\\/distributed-generation-app .checklist-info-i.more-margin {
    margin-left: 0.75rem;
}

`, "",{"version":3,"sources":["webpack://./src/base.css"],"names":[],"mappings":"AAAA;IACI,4EAA4E;AAChF;;AAEA;IACI;QACI,2BAA2B;IAC/B;AACJ;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,QAAQ;IACR,SAAS;IACT,kBAAkB;IAClB,mCAAmC;IACnC,oCAAoC;IACpC,iCAAiC;IACjC,8BAA8B;IAC9B,SAAS;IACT,WAAW;IACX,wBAAwB;AAC5B;;AAEA;IACI,aAAa;IACb,4DAA4D;IAC5D,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;IACX,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":[".form-layout {\n    grid-template-columns: minmax(min-content, 35rem) minmax(min-content, 35rem);\n}\n\n@media (max-width: 1024px) {\n    .form-layout {\n        grid-template-columns: none;\n    }\n}\n\n.full-main-container-width {\n    margin-left: -3rem;\n    margin-right: -3rem;\n}\n\n.electricity-connection {\n    border-color: #33ab3e;\n    color: #33ab3e;\n}\n\n.speech-bubble:after {\n    content: '';\n    width: 0;\n    height: 0;\n    position: absolute;\n    border-left: 25px solid transparent;\n    border-right: 25px solid transparent;\n    border-top: 6px solid transparent;\n    border-bottom: 13px solid #fff;\n    top: 30px;\n    left: -20px;\n    transform: rotate(65deg);\n}\n\n.plant-components-details-table {\n    display: grid;\n    grid-template-columns: 24fr 18fr 9fr 7fr 8fr 8fr 8fr 8fr 6fr;\n    column-gap: 0.75rem;\n}\n\n.checklist-info-i {\n    display: inline-block;\n    vertical-align: middle;\n    fill: currentColor;\n    width: 1rem;\n    margin-bottom: 0.1rem;\n    margin-left: 0.5rem;\n}\n\n.checklist-info-i.more-margin {\n    margin-left: 0.75rem;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
